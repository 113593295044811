import { Helmet } from 'react-helmet'
import React from 'react'
import SEO from '../../components/seo'
import JotFormReact from 'jotform-react'

const Detail = () => {
  const topBannerTextContent = (
    <>
      No Matter Where You Look, You Can See Your Future In Genesis!
      <br />
      <span style={{ color: 'black' }}>
        <em>"EARN AND LEARN"</em>
      </span>
    </>
  )

  const subHeaderImage = (
    <img src="/landingpages/nurse-aide-training/NurseAideTrainingRearviewMirror.jpg" />
  )

  const testimonials = [
    {
      photoUrl: 'KathrineMidgettePhoto.jpg',
      name: 'Kathrine Midgette',
      title: 'Certified Nursing Assistant',
      location: 'Salisbury Nursing & Rehabilitation (Maryland)',
      testimonialText: (
        <>
          "I started working as a Unit Assistant at Salisbury Nursing and
          Rehabilitation Center while taking advantage of their nurse aide
          training program. I recently graduated with my CNA and passed my
          license/certification exam. I plan on becoming an RN. Genesis has
          given me a career path and I am so grateful. Without this program, I
          would never have been able to start this journey!"
        </>
      ),
    },
    {
      photoUrl: 'HunterLindseyPhoto.jpg',
      name: 'Hunter Lindsey',
      title: 'CNA Care Specialist I',
      location: 'Village of Northrise (New Mexico)',
      testimonialText: (
        <>
          "My favorite part of my job is that, every day at work, I get to make
          a difference. I know that I get to help people with being more
          comfortable, especially considering what's going on right now in the
          world."
          <br /> Read about Hunter's journey{' '}
          <a
            href="https://www.lcsun-news.com/story/news/2020/10/28/trauma-leads-one-las-cruces-man-lifelong-journey-care-others/6009532002/"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
    },
    {
      photoUrl: 'LorettaSherenPhoto.jpg',
      name: 'Loretta Sheren',
      title: 'Certified Nursing Assistant',
      location: 'Lofland Park (Delaware)',
      testimonialText:
        '"Ten years ago, I began working at Lofland Center in dietary and housekeeping. Becoming a CNA had always been a dream of mine, but I wasn’t sure I could do it. The Lofland team encouraged me to give it a try and I quickly realized I could make a difference in people’s lives. I leave work every day knowing I helped my residents!"',
    },
    {
      photoUrl: 'HannahMillmanPhoto.jpg',
      name: 'Hannah Millman',
      title: 'Assistant Activities Director',
      location: 'Seaford Center (Delaware)',
      testimonialText:
        '"With one child and another on the way, I knew that my family was going to need much more stability than my job as a restaurant server could offer. Thank you to Genesis for taking a risk on me, and for teaching me skills that I need to ensure the safety, happiness, and proper care of my patients. After becoming a CNA, I was promoted to Assistant Activities Director, and am working on a new certification so that one day I can fulfill my goal of being the best Recreation Director possible!"',
    },
    {
      photoUrl: 'WendyBlythePhoto.jpg',
      name: 'Wendy Blythe',
      title: 'Staffing Coordinator',
      location: 'Genesis Staffing Services (Maryland)',
      testimonialText:
        '"Since I was 16 years old, I worked in assisted living and nursing homes helping the elderly. It has always been a passion of mine to help others. I started off as a dishwasher and worked my way to CNA, med tech, resident care manager in Assisted Living, and now, Staffing Coordinator. If you have a passion for something, go for it and never allow anyone to make you feel like you cannot achieve your dreams or goals . Always strive to be your best. There is always someone out there that needs a helping hand."',
    },
  ]

  return (
    <>
      <SEO title={`Genesis HealthCare > Nurse Aid Training`} />
      <Helmet>
        <link
          type="text/css"
          rel="stylesheet"
          href="/landingpages/nurse-aide-training/default.css"
        />{' '}
        <link
          type="text/css"
          rel="stylesheet"
          href="/landingpages/nurse-aide-training/font-awesome.min.css"
        />
        <link
          type="text/css"
          rel="stylesheet"
          href="/landingpages/nurse-aide-training/css.css"
        />
        <link
          type="text/css"
          rel="stylesheet"
          href="/landingpages/nurse-aide-training/icon.css"
        />
      </Helmet>
      <main>
        <div className="content-wrapper">
          <div className="page-header">
            <img src="/landingpages/nurse-aide-training/GHCLogo-one-colorWhite.png" />
            <span>Nurse Aide Training</span>
          </div>
          <div className="top-banner">
            <div className="top-banner-text-inside">{topBannerTextContent}</div>
          </div>
          <div className="top-banner-text-below">{topBannerTextContent}</div>

          <div className="subheader-wrapper ">
            <div className="subheader-text">
              <div className="subheader-topline">
                Want to become a certified/licensed nurse aide but not sure
                where to begin?
              </div>
              <div className="subheader-image-above">{subHeaderImage}</div>
              <div className="subheader-lower-text">
                We’re looking for motivated and compassionate individuals who
                care deeply and want to make a difference in the lives of
                others. No experience necessary! Join our team today, and we
                will cover the cost of your tuition and training fees for a
                state-approved nurse aide training program.
              </div>
            </div>
            <div className="subheader-image-right">{subHeaderImage}</div>
          </div>
          <div className="lower-page-section">
            <div className="top-text-section-topline">
              We have been training and developing nurse aides for more than 20
              years, with 10,000+ graduates and counting.
            </div>
            <div className="text-section-wrapper">
              <div className="text-section-header">How does it work?</div>
              <div className="text-section-text">
                Participants are hired into a paid, non-patient care role in one
                of our skilled nursing facilities. During this period of
                employment, we cover the cost of a state-approved nurse aide
                training program while providing on-the-job support and
                experience. Upon successful completion of the training program,
                participants receive their license or certification and
                transition into a nurse aide position where they will be able to
                provide hands-on patient care! From start to finish, the program
                typically takes up to four months to complete.
              </div>
            </div>
            <div className="text-section-wrapper">
              <div className="text-section-header">Training Locations</div>
              <div className="text-section-text">
                We offer state-approved nurse aide training programs at many of
                our skilled nursing facilities. Where on-site training is not
                available, we work with outside organizations to provide local
                and/or on-line nurse aide training opportunities.
                <br />
                <br />
              </div>
              <div className="text-section-header">How Do I Apply?</div>
              <div className="text-section-text">
                Visit{' '}
                <a href="https://www.genesiscareers.jobs">
                  www.genesiscareers.jobs
                </a>{' '}
                and search for "Paid Nurse Aide Training".
                <br />
                <br />
              </div>
            </div>
          </div>
          {/* <div className="bio-content-wrapper">
            <JotFormReact
              formURL="https://form.jotform.com/212995061907159"
              initialHeight={750}
              autoResize={true}
            />
          </div> */}
          <div className="bio-content-wrapper">
            <div className="content-wrapper">
              <div className="bio-section-wrapper">
                <div className="bio-section-header">
                  Here’s what our graduates say
                </div>
                {testimonials.map((t) => {
                  return (
                    <div className="bio-wrapper">
                      <div className="bio-image-wrapper">
                        <img
                          className="bio-image"
                          src={`/landingpages/nurse-aide-training/${t.photoUrl}`}
                        />
                      </div>
                      <div className="bio-text-wrapper">
                        <div className="bio-name">{t.name}</div>
                        <div className="bio-title">{t.title}</div>
                        <div className="bio-location">{t.location}</div>
                        <div className="bio-text">{t.testimonialText}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className="content-wrapper">
            <div className="last-section-wrapper">
              <div className="text-section-wrapper">
                <div className="text-section-header">
                  Work with the Best, Learn from the Best!
                </div>
                <div className="text-section-text">
                  As one of the largest providers of post-hospital
                  rehabilitation and long-term care in the nation, we have the
                  career path you have been waiting for.
                </div>
                {/* <div className="text-section-find">
                  {' '}
                  <a
                    href="https://www.genesiscareers.jobs/main/jobs?keywords=training&page=1&sortBy=relevance&categories=Nursing%20-%20CNA%7CNursing%20-%20Non-Certified%20Nursing%20Assistant"
                    target="_blank"
                  >
                    <span className="top-text-section-CNATraining-button">
                      Search CNA Training Positions
                    </span>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="footer">
          <div className="contents">
            <div className="footer-top">
              <ul className="left">
                <li id="facebook" className="social-icon">
                  <a
                    href="https://www.facebook.com/GenesisCareers/"
                    token-data="FOOTER.FACEBOOK"
                    token-type="text"
                  >
                    <img src="/landingpages/nurse-aide-training/fb-icon.jpg" />
                  </a>
                </li>
                <li id="instagram" className="social-icon">
                  <a
                    href="https://www.instagram.com/genesiscareers/?hl=en"
                    token-data="FOOTER.INSTAGRAM"
                    token-type="text"
                  >
                    <img src="/landingpages/nurse-aide-training/inst-icon.jpg" />
                  </a>
                </li>
                <li id="linkedin" className="social-icon">
                  <a
                    href="https://www.linkedin.com/company/genesis-healthcare/"
                    token-data="FOOTER.LINKEDIN"
                    token-type="text"
                  >
                    <img src="/landingpages/nurse-aide-training/li-icon.jpg" />
                  </a>
                </li>
                <li id="twitter" className="social-icon">
                  <a
                    href="https://twitter.com/GenesisCareers?lang=en"
                    token-data="FOOTER.TWITTER"
                    token-type="text"
                  >
                    <img src="/landingpages/nurse-aide-training/tw-icon.jpg" />
                  </a>
                </li>
                <li id="glassdoor" className="social-icon">
                  <a
                    href="https://www.glassdoor.com/Overview/Working-at-Genesis-HealthCare-EI_IE33727.11,29.htm"
                    token-data="FOOTER.GLASSDOOR"
                    token-type="text"
                  >
                    <img src="https://www.glassdoor.com/pc-app/static/img/partnerCenter/badges/eng_CIRCLE_64x64.png" />
                  </a>
                </li>{' '}
                <li id="indeed" className="social-icon">
                  <a
                    href="https://www.indeed.com/cmp/Genesis-Healthcare"
                    token-data="FOOTER.INDEED"
                    token-type="text"
                  >
                    <img
                      src="/landingpages/nurse-aide-training/id-icon.jpg"
                      token-data="FOOTER.INDEED-ICON"
                      token-type="media"
                      alt="corporate"
                    />
                  </a>
                </li>{' '}
                <li id="youtube" className="social-icon">
                  <a
                    href="https://www.youtube.com/channel/UCvxoxN_m9nseb8mquc158SQ"
                    token-data="FOOTER.YOUTUBE"
                    token-type="text"
                  >
                    <img src="/landingpages/nurse-aide-training/yt-icon.jpg" />
                  </a>
                </li>
              </ul>
              <ul className="right">
                <li>
                  Contact us:{' '}
                  <a href="mailto:sourcing@genesishcc.com">
                    sourcing@genesishcc.com
                  </a>
                </li>
                <li>© 2020 Genesis HealthCare™</li>
              </ul>
            </div>
            <div className="footer-bottom">
              <p>
                Genesis HealthCare, Inc. and all affiliated entities
                (collectively, “Genesis”) provides equal employment
                opportunities (EEO) to all employees and applicants for
                employment without regard to race, color, religion, gender,
                gender expression, gender identity, sexual orientation, national
                origin, age, actual or perceived disability, marital status,
                pregnancy, genetic information, amnesty, military status or
                status as a covered veteran in accordance with applicable
                federal, state, and local laws. Genesis takes affirmative action
                to employ and advance in employment qualified minorities, women,
                individuals with disabilities and covered veterans. These
                policies are fully supported by the CEO, COO and all members of
                the leadership team.
              </p>
              <p>
                If you require any reasonable accommodation under the Americans
                with Disabilities Act (ADA) in completing the online
                application, interviewing, completing any pre-employment testing
                or otherwise participating in the employee selection process,
                please contact (877)456-6726.{' '}
                <u>
                  This phone number is for ADA related accommodation requests
                  only, not for routine employment inquiries.
                </u>
              </p>
              <p>
                Please be aware that scammers may target job applicants with
                fake job offers (particularly work from home offers) in order to
                gain access to personal information or financial accounts.
                Hiring representatives from Genesis HealthCare and our
                affiliates do not have email addresses with a generic extension
                (e.g. @gmail, @yahoo, @hotmail). If you have concerns about the
                validity of a job posting or offer, please email
                reachout@genesishcc.com or call the Genesis Reach Out Line at
                1-800-893-2094.
              </p>
              <p></p>
              <ul className="list">
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.genesishcc.com/about-us/privacy"
                    token-data="FOOTER.BOTTOM-LINK-1"
                    token-type="text"
                  >
                    Privacy
                  </a>
                </li>
                <li className="bar">|</li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://www.genesishcc.com/about-us/compliance"
                    token-data="FOOTER.BOTTOM-LINK-2"
                    token-type="text"
                  >
                    Compliance
                  </a>
                </li>
                <li className="bar">|</li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.genesishcc.com/about-us/legal"
                    token-data="FOOTER.BOTTOM-LINK-3"
                    token-type="text"
                  >
                    Legal
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Detail
